$unit: 16;
$spaceamounts: (8, 12, 16, 24, 32, 40, 48, 56, 80);
$sides: (top, bottom, left, right, all);

@each $space in $spaceamounts {
  @each $side in $sides {

    @if $side == 'all' {
      %m#{$space} {
        margin: #{$space / $unit}rem; }

      .m#{$space} {
        margin: #{$space / $unit}rem; }

      %p#{$space} {
        padding: #{$space / $unit}rem; }

      .p#{$space} {
        padding: #{$space / $unit}rem; } }
    @else {
      %m#{str-slice($side, 0, 1)}#{$space} {
        margin-#{$side}: #{$space / $unit}rem; }

      .m#{str-slice($side, 0, 1)}#{$space} {
        margin-#{$side}: #{$space / $unit}rem; }

      %p#{str-slice($side, 0, 1)}#{$space} {}

      .p#{str-slice($side, 0, 1)}#{$space} {
        padding-#{$side}: #{$space / $unit}rem; } } } }
