@import "../../node_modules/roboto-fontface/css/roboto/roboto-fontface.css";
@import "../../node_modules/typeface-rubik/index.css";
@import "../../node_modules/@fortawesome/fontawesome-free/scss/regular";
@import "../../node_modules/@fortawesome/fontawesome-free/scss/solid";
@import "../../node_modules/@fortawesome/fontawesome-free/scss/brands";
@import "../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome";

$family-sans-serif: "Roboto", sans-serif;
$weight-bold: 500;
$weight-semibold: 500;
$title-line-height: 1.3;
$subtitle-line-height: 1.4;
$text: #01131a;
$link: #3da5d9;
$primary: #2fb671;
$navbar-height: 5.5rem;
$input-placeholder-color: $text;
$input-height: 3.5rem;
$input-radius: 4px 4px 0 0;
$input-border-color: none;
$navbar-item-img-max-height: 2.5rem;

@import "../../node_modules/bulma/bulma.sass";
@import "color-helpers.sass";
@import "margin-padding.sass";
@import "color-helpers.sass";
@import "responsive-utils.sass";
@import "sdgs.sass";
@import "board-card.sass";
@import "principle-card.sass";
@import "ftsf-bullet.sass";
@import "forms.sass";
