$SDG-17: #19486a;
$SDG-16: #00689d;
$SDG-15: #56c02b;
$SDG-14: #0a97d9;
$SDG-13: #3f7e44;
$SDG-12: #bf8b2e;
$SDG-11: #fd9d24;
$SDG-10: #dd1367;
$SDG-9: #fd6925;
$SDG-8: #a21942;
$SDG-7: #fcc30b;
$SDG-6: #26bde2;
$SDG-5: #ff3a21;
$SDG-4: #c5192d;
$SDG-3: #4c9f38;
$SDG-2: #dda63a;
$SDG-1: #e5243b;

%is-SDG-1 {
    background-color: $SDG-1; }

%is-SDG-2 {
    background-color: $SDG-2; }

%is-SDG-3 {
    background-color: $SDG-3; }

%is-SDG-4 {
    background-color: $SDG-4; }

%is-SDG-5 {
    background-color: $SDG-5; }

%is-SDG-6 {
    background-color: $SDG-6; }

%is-SDG-7 {
    background-color: $SDG-7; }

%is-SDG-8 {
    background-color: $SDG-8; }

%is-SDG-9 {
    background-color: $SDG-9; }

%is-SDG-10 {
    background-color: $SDG-10; }

%is-SDG-11 {
    background-color: $SDG-11; }

%is-SDG-12 {
    background-color: $SDG-12; }

%is-SDG-13 {
    background-color: $SDG-13; }

%is-SDG-14 {
    background-color: $SDG-14; }

%is-SDG-15 {
    background-color: $SDG-15; }

%is-SDG-16 {
    background-color: $SDG-16; }

%is-SDG-17 {
    background-color: $SDG-17; }

@for $i from 1 through 17 {
  .tag:not(body).is-SDG-#{$i} {
    @extend %is-SDG-#{$i};
    color: #fff; } }

.tag:not(body).is-SDG {
    color: #fff; }
