.board {
  .board-card:not(:first-child) {
    margin-left: 24px; } }

.board-card {
  width: 15rem;
  display: inline-block;

  .avatar {
    width: 100%;
    height: 15rem;
    background-size: cover;
    background-position: center;
    background-color: gray;
    position: relative;

    .link {
      width: 3rem;
      height: 3rem;
      border-radius: 100%;
      position: absolute;
      left: calc(7.5rem - 1.5rem);
      bottom: 16px;
      color: $blue900;
      background-color: white;
      visibility: hidden; } }

  &:hover {
    .avatar {
      border-radius: 20px;

      .link {
        visibility: inherit;

        &:hover {
          color: white;
          background-color: $blue900; } } } } }
