.select {
  width: 100%;

  select {
    width: 100%; } }

.select select, .textarea, .input {
  border-bottom-color: $blue900;
  font-family: "Rubik", sans-serif;
  color: $text;
  height: $input-height;

  &:hover, &:active {
    border-bottom-color: $blue900; } }
