$blue900: #05668d;
$blue50: #e3f7f9;

.has-background-blue50 {
  background-color: $blue50; }

.has-background-blue900 {
  background-color: $blue900; }

.has-background-light-green {
  background-color: #e3f4ea; }

.grey-link {
  color: #677176; }

.black-link {
  color: $text; }
